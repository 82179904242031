'use client';
import React from 'react';
import { IntercomBubble } from '@mentimeter/intercom';
import { LoadingOrError } from 'components/LoadingOrError';
import { usePageError } from '@mentimeter/errors/usePageError';

const FiveHundred = ({ error }: { error: Error }) => {
  usePageError({
    error,
    message: 'Something went wrong and got caught in root-error',
    feature: 'root-error',
  });

  return (
    <>
      <IntercomBubble />
      <LoadingOrError error="Something went wrong..." />
    </>
  );
};

export default FiveHundred;
